import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import DialogContentText from '@mui/material/DialogContentText'
import Grid from '@mui/material/Grid2'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowParams,
  GridToolbar,
} from '@mui/x-data-grid'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { ApiError, axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import DynamicDialog from '../../components/DynamicDialog'
import Iconify from '../../components/iconify'
import { Tenant, useAuth } from '../../context/AuthProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import {
  EmployeeInformation,
  EmployeeStatus,
  getFullName,
  PaymentType,
} from '../../types/Employee'
import EmployeeFilters from './EmployeeFilters'
import EmployeeSkeletons from './EmployeeSkeletons'

type GetEmployeesInformationRequest = {
  tenantId: number | null
  employeeStatus: EmployeeStatus
  paymentTypeId: number | null
}

const EmployeeView: FC = () => {
  const [employees, setEmployees] = useState<EmployeeInformation[]>([])
  const [filteredEmployees, setFilteredEmployees] = useState<EmployeeInformation[]>([])
  const { auth } = useAuth()
  const { showSuccess, showError } = useSnackbar()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [deletingEmployeeId, setDeletingEmployeeId] = useState<number | null>(null)
  const [selectedEmployeeStatus, setSelectedEmployeeStatus] = useState<EmployeeStatus>(EmployeeStatus.Active)
  const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null)
  const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentType | null>(null)

  useEffect(() => {
    setFilteredEmployees(employees)
  }, [employees])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    if (isMounted) {
      getEmployees()
    }

    return () => {
      isMounted = false
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenant?.id, selectedEmployeeStatus, selectedPaymentType])

  const getEmployees = async () => {
    try {
      const getEmployeesInformationRequest: GetEmployeesInformationRequest = {
        tenantId: selectedTenant?.id ?? null,
        employeeStatus: selectedEmployeeStatus,
        paymentTypeId: selectedPaymentType ? selectedPaymentType.id : null,
      }

      setIsLoading(true)
      const response = await axiosPrivate.post(
        endpoints.getEmployeesInformation,
        getEmployeesInformationRequest
      )
      setEmployees(response.data)
      setIsLoading(false)
    } catch (err: any) {
      const error = err as AxiosError
      if (error.name !== 'CanceledError') {
        setIsLoading(false)
        showError('Error al cargar los empleados')
      }
    }
  }

  const handleEditRow = (id: GridRowId) => () => {
    const employeeToEdit = employees.find((product) => product.id === id)
    navigate(`/empleados/editar/${id}`, {
      state: { currentProduct: employeeToEdit },
    })
  }

  const handleDeleteRow = (id: GridRowId) => () => {
    setDeletingEmployeeId(Number(id))
    setOpenDialog(true)
  }

  const handleOnCloseDelete = () => {
    setOpenDialog(false)
  }

  const handleOnConfirmDelete = async () => {
    if (deletingEmployeeId !== null) {
      await RemoveEmployee(Number(deletingEmployeeId))
      setOpenDialog(false)
    }
  }

  const dialogAcceptButton = {
    text: 'Eliminar',
    action: handleOnConfirmDelete,
  }

  const dialogCancelButton = {
    text: 'Cancelar',
    action: handleOnCloseDelete,
  }

  const RemoveEmployee = async (productId: number) => {
    setIsLoading(true)
    const controller = new AbortController()

    const SendDeleteProductRequest = async () => {
      try {
        const url = endpoints.deleteProduct
          .replace('{productId}', productId.toString())
          .replace('{deletedByUserEmail}', auth.user?.email || '')

        const response = await axiosPrivate.delete(url, {
          signal: controller.signal,
        })

        if (response.status === 200) {
          showSuccess('Producto eliminado correctamente!')
          navigate('/inventario-productos')
        }
      } catch (err: any) {
        const error = err as AxiosError<ApiError>

        if (error.name !== 'CanceledError') {
          showError(
            'Error eliminar el producto ' + error?.response?.data?.detail
          )
        }
      } finally {
        setIsLoading(false)
        getEmployees()
      }
    }

    SendDeleteProductRequest()
  }
  

  const columns: GridColDef<EmployeeInformation>[] = [
    {
      headerName: 'Identificacion',
      field: 'idDocument',
      valueGetter: (value, row) => row.idDocument,
      minWidth: 158,
      flex: 1,
      renderCell: (
        params // Text wrapping
      ) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal' }}>
          {params.value}
        </div>
      ),
    },
    {
      headerName: 'Nombre',
      field: 'firstName',
      valueGetter: (value, row) => getFullName(row),
      minWidth: 160,
      flex: 1,
      renderCell: (
        params // Text wrapping
      ) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal' }}>
          {params.value}
        </div>
      ),
    },
    {
      headerName: 'Pago',
      field: 'paymentTypeDescription',
      minWidth: 125,
      flex: 1,
    },
    {
      headerName: 'Perfiles',
      field: 'tenants',
      valueGetter: (value, row) => row.tenants.length,
      minWidth: 125,
    },
    {
      headerName: 'Estado',
      field: 'isDeleted',
      valueGetter: (value, row) => (row.isDeleted ? 'Desactivado' : 'Activo'),
      minWidth: 125,
      renderCell: (params) => (
        <span
          style={{
            color: params.value === 'Desactivado' ? '#B71D18' : '#118D57',
            backgroundColor:
              params.value === 'Desactivado'
                ? 'rgba(255, 86, 48, 0.16)'
                : 'rgba(34, 197, 94, 0.16)',
            padding: '5px',
            borderRadius: '3px',
          }}
        >
          {params.value}
        </span>
      ),
    },
    // {
    //   field: 'actions',
    //   type: 'actions',
    //   headerName: '',

    //   getActions: (params: GridRowParams<EmployeeInformation>) => [
    //     <GridActionsCellItem
    //       sx={{ p: 0, m: 0 }}
    //       icon={<VisibilityIcon />}
    //       label='Ver'
    //       className='textPrimary'
    //       onClick={handleEditRow(params.row.id)}
    //       color='inherit'
    //     />,
    //     <GridActionsCellItem
    //       sx={{ p: 0, m: 0 }}
    //       icon={<EditIcon />}
    //       label='Editar'
    //       className='textPrimary'
    //       onClick={handleEditRow(params.row.id)}
    //       color='inherit'
    //     />,
    //     <GridActionsCellItem
    //       sx={{ p: 0, m: 0 }}
    //       icon={<DeleteIcon />}
    //       label='Eliminar'
    //       onClick={handleDeleteRow(params.row.id)}
    //       color='inherit'
    //     />,
    //   ],
    // },
  ]

  return (
    <Container>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Grid container sx={{ width: '100%' }}>
          <Grid size={{ xs: 12, md: 8 }}>
            <Typography variant='h4'>Empleados</Typography>
          </Grid>

          <Grid
            size={{ xs: 12, md: 4 }}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'flex-start', md: 'flex-end' },
            }}
          >
            {/* <Button
              component={NavLink}
              to='/inventario-productos/agregar'
              variant='contained'
              color='inherit'
              startIcon={<Iconify icon='eva:plus-fill' />}
            >
              Agregar Empleado
            </Button> */}
          </Grid>
        </Grid>
      </Stack>

      <EmployeeFilters
        selectedEmployeeStatus={selectedEmployeeStatus}
        onEmployeeStatusChange={setSelectedEmployeeStatus}
        selectedTenant={selectedTenant}
        setSelectedTenant={setSelectedTenant}
        selectedPaymentType={selectedPaymentType}
        setSelectedPaymentType={setSelectedPaymentType}
      />

      <Card>
        <CardContent>
          <Grid container spacing={3}>
            {isLoading ? (
              <EmployeeSkeletons count={5} />
            ) : (
              <Grid size={12}>
                <DataGrid
                  rows={filteredEmployees}
                  columns={columns}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableRowSelectionOnClick
                  density='compact'
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: {
                        debounceMs: 300,
                        placeholder: 'Buscar',
                      },
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                    },
                  }}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      <DynamicDialog
        openDialog={openDialog}
        dialogTitle='Confirmar eliminación'
        onCloseAction={handleOnCloseDelete}
        buttonAccept={dialogAcceptButton}
        buttonCancel={dialogCancelButton}
      >
        <DialogContentText id='generic-dialog-description'>
          ¿Realmente desea deshabilitar este empleado?
        </DialogContentText>
      </DynamicDialog>
    </Container>
  )
}

export default EmployeeView
