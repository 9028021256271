import { FC, useEffect, useState } from 'react'
import { useAuth, Tenant, User } from '../../context/AuthProvider'
import { useNavigate, useLocation } from 'react-router-dom'
import { endpoints } from '../../api/constants'
import { Box, Container, Typography } from '@mui/material'
import { styles } from "./LoginStyles"
import Loading from '../../components/Loading/Loading'
import TenantSelector from "./TenantSelector"
import { setLocalStorageItem } from '../../utils/localStorageUtils'
import { useSnackbar } from '../../context/SnackbarProvider'
import useAxiosPrivateLogin, { AxiosError } from '../../hooks/useAxiosPrivateLogin'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'

export type UserInfoResponse = {
  id: number
  email: string
  firstName: string
  lastName: string  
  imageUrl?: string
  isApprover: boolean
  isAdmin: boolean
  tenants: Tenant[]
}

const LoginInfo: FC = () => {
  const { setAuth } = useAuth()
  const navigate = useNavigate()
  const { axiosPrivate, isAxiosReady } = useAxiosPrivateLogin()
  const [tenants, setTenants] = useState<Tenant[]>([])  
  const [user, setUser] = useState<User>()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { showError } = useSnackbar()
  const location = useLocation()
  const from = location.state?.from?.pathname || "/"

  useEffect(() => {    
    let isMounted = true
    const controller = new AbortController()
    const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))
    
    const getUserInfo = async () => {
      try {
        const response = await axiosPrivate.get(
          endpoints.getUserInfo,
          { signal: controller.signal }
        )
        if (isMounted) {                
          const userInfoResponse = response.data as UserInfoResponse
          setTenants(userInfoResponse.tenants)
          setUser(
            {
              userid: userInfoResponse.id,
              username: `${userInfoResponse.firstName} ${userInfoResponse.lastName}`,
              email: userInfoResponse.email,
              imageurl: userInfoResponse.imageUrl,
              isApprover: userInfoResponse.isApprover,
              isAdmin: userInfoResponse.isAdmin
            }
          )
          if (userInfoResponse.tenants && userInfoResponse.tenants.length > 1) {      
            setOpenModal(true)
          } else if (userInfoResponse.tenants.length === 1) {
            handleSetAuth(userInfoResponse.tenants[0])
          } else {        
            showError('El usuario no tiene ningún perfil asociado') // TODO: Show error message
            return
          }         
        }
       } catch (err: any) {       
        const error = err as AxiosError       
        if (error.name === 'CanceledError') {
          console.log('The request has been canceled - Login')
        } else if (error.response && error.response.status === 400) {         
          showError((error.response.data as any)?.errors.join(' ') || 'Bad request error')
          await sleep(3000)
          navigate('/logout', { replace: true })
        } else {
          console.error(err)
        }
      }
    }

    if (isAxiosReady) {       
      getUserInfo()
    }

    return () => {
      isMounted = false
      controller.abort()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAxiosReady])

  const handleSetAuth = (tenant: Tenant) => {
    setAuth({ user, tenants, selectedTenant: tenant })
    setLocalStorageItem(process.env.REACT_APP_LOCAL_STORAGE_KEY_SELECTED_TENANT as string, JSON.stringify(tenant))
    navigate(from, { replace: true })
}

const handleTenantSelect = (tenant: Tenant) => {   
  setOpenModal(false)
  handleSetAuth(tenant)
}

  return (
    <MsalAuthenticationTemplate
    interactionType={InteractionType.Redirect}>
       <Container>
       <TenantSelector
          open={openModal}
          tenants={tenants}
          onSelect={handleTenantSelect}
        />  
       </Container>
    </MsalAuthenticationTemplate>
  )

}

const Login: FC = () => {
  return (
      <Container sx={styles.loginContainer} >
        <Box>          
          <Typography variant='h3' sx={ styles.appName }>Sistema Planilla</Typography >
          <Typography variant='h5' sx={ styles.loginText }>Iniciando sesión...</Typography>
          <Loading centered={false}/>
        </Box>              
        <LoginInfo />
      </Container>   
  )
}

export default Login
