import { Tenant } from "../context/AuthProvider"

export type Employee = {
  id: number
  firstName: string
  lastName: string
  paymentTypeDescription: string
}

export function getFullName(employee: Employee) {
  return `${employee.firstName} ${employee.lastName}`
}

export type PaymentType = {
  id: number
  name: string
}
export type Bank = {
  id: number
  name: string
}

export type EmployeeBankAccount = {
  id: number
  bank?: Bank
  accountNumber: string
  isDefault: boolean
  ownerName?: string
  ownerIdDocument?: string
}

export type EmployeeInformation = {
  id: number
  idDocument: string
  birthDate: string
  firstName: string
  lastName: string
  paymentTypeDescription: string
  address: string
  phoneNumber?: string
  gender: string
  isDeleted: boolean
  tenants: Tenant[]
  employeeBankAccounts: EmployeeBankAccount[]
}

export enum EmployeeStatus {
  Active = 1,
  Inactive = 2,
  All = 3
}

export function getJournalDailyWorkPaymentStatusLabel(status: EmployeeStatus): string {
switch (status) {
  case EmployeeStatus.Active:
    return "Activo";
  case EmployeeStatus.Inactive:
    return "Desactivado";
  case EmployeeStatus.All:
    return "Todo";
  default:
    return "Desconocido";
}
}